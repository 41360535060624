<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="updateDueDateForm"
        ref="updateDueDateForm"
        class="custom-form pt-8"
        @submit.prevent="updateDueDate"
      >
        <v-row>
          <v-col cols="12">
            <v-menu
              v-model="showPicker"
              v-bind="attributes.menu"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  :value="displayedDate"
                  class="pt-0 input--date-picker"
                  label="Data realizacji"
                  :rules="showPicker ? [] : [hasDateChanged]"
                  placeholder="Wprowadź datę realizacji"
                  v-bind="{...attrs, ...attributes.textField}"
                  v-on="on"
                  @click.native="showPicker = !showPicker"
                >
                  <template #prepend-inner>
                    <Icon
                      name="date"
                      size="small"
                      class="mr-2"
                    />
                  </template>
                  <template #append>
                    <SwitchDayButtons
                      v-model="date"
                      :min="new Date().toISOString().substring(0,10)"
                    />
                  </template>
                </v-text-field>
              </template>

              <v-date-picker
                v-model="date"
                v-bind="attributes.datePicker"
                :min="new Date().toISOString().substring(0,10)"
                @input="showPicker = false"
              />
            </v-menu>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        type="submit"
        form="updateDueDateForm"
        v-shortkey="{enter:['enter']}"
        @shortkey.native="updateDueDate"
      >
        Zapisz zmianę
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import attributes from '../../const/datePickerAttrributes'
import { dateStringFormat } from '../../utils'
import { mapState, mapActions } from 'vuex'
import { Course } from '../../models/index'

export default {
  components: {
    DialogFormWrapper
  },
  props: {
    tableName: {
      type: String,
      default: 'courses'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    allCourses: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      attributes,
      showPicker: false,
      date: null
    }
  },
  computed: {
    ...mapState({
      dialogItem: state => state.layout.dialog.item
    }),
    item () {
      return new Course(this.dialogItem)
    },
    tableDateRange () {
      return this.$store.state[this.tableName].dateRange
    },
    displayedDate () {
      return dateStringFormat(this.date)
    },
    isProcessing() {
      return this.$store.state[this.tableName].isProcessing
    }
  },
  created () {
    this.date = this.multiple ? this.tableDateRange[0] : this.item.dueDate
  },
  methods: {
    ...mapActions({
      editCourseDueDate: 'courses/editCourseDueDate',
      editMultipleCoursesDueDate: 'courses/editMultipleCoursesDueDate',
      editAllCoursesDueDate: 'courses/editAllCoursesDueDate',
      editDueDate: function(dispatch) {
        return dispatch(`${this.tableName}/editItem`, { tableName: this.tableName, id: this.item.id, params: { dueDate: this.date } }).then(() => {
          dispatch(`${this.tableName}/getItems`)
        })
      }
    }),
    hasDateChanged (date) {
      const rawDate = this.multiple ? this.tableDateRange[0] : this.item.dueDate
      return date !== dateStringFormat(rawDate) || 'Nie zmieniono daty'
    },
    updateDueDate () {
      if (this.$refs.updateDueDateForm.validate()) {
        if (this.tableName === 'courses') {
          if (this.allCourses) {
            this.editAllCoursesDueDate({ dueDate: this.date })
          } else if (this.multiple) {
            this.editMultipleCoursesDueDate({ dueDate: this.date })
          } else {
            this.editCourseDueDate({ id: this.item.id, dueDate: this.date })
          }
        } else {
          this.editDueDate()
        }
      }
    }
  }
}
</script>
